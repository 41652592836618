import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      {/* <meta name="twitter:url" content={url} /> */}
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:image" content={image} /> */}
      <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>"
      />
    </Helmet>
  );
};

export default SEO;
