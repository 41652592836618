import React from "react";
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

const Desktop = ({ data }) => {
  return (
    <div className="home_landing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="museum home_animation9" id={1}>
              {/* Logo */}
              <center>
                <Link to={data.logo.slug.current}>
                  <GatsbyImage
                    image={data.logo.asset.gatsbyImageData}
                    alt={data.logo.alt || data.logo.title}
                    className="tatto2"
                    loading="eager"
                  />
                </Link>{" "}
              </center>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="library home_animation4" id={2}>
              <Link to={data.tatoo.slug.current}>
                <GatsbyImage
                  image={data.tatoo.asset.gatsbyImageData}
                  alt={data.tatoo.alt || data.tatoo.title}
                  className="tatto6"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 col-md-7 col-12">
            <div className="museum home_animation1" id={5}>
              <Link to={data.meseum.slug.current}>
                <GatsbyImage
                  image={data.meseum.asset.gatsbyImageData}
                  alt={data.meseum.alt || data.meseum.title}
                  className="tatto1"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-12">
            <div className="movie_theatre home_animation2" id={4}>
              <Link to={data.theater.slug.current}>
                <GatsbyImage
                  image={data.theater.asset.gatsbyImageData}
                  alt={data.theater.alt || data.theater.title}
                  className="tatto3"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="library home_animation3" id={3}>
              <Link to={data.library.slug.current}>
                <GatsbyImage
                  image={data.library.asset.gatsbyImageData}
                  alt={data.library.alt || data.library.title}
                  className="tatto4"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <div className="radio_s home_animation5">
              <Link to={data.radio.slug.current}>
                <GatsbyImage
                  image={data.radio.asset.gatsbyImageData}
                  alt={data.radio.alt || data.radio.title}
                  className="tatto7"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="cemetery home_animation6">
              <Link to={data.cemetery.slug.current}>
                <GatsbyImage
                  image={data.cemetery.asset.gatsbyImageData}
                  alt={data.cemetery.alt || data.cemetery.title}
                  className="tatto8"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="social_web home_animation7">
              <Link to={data.social.slug.current}>
                <GatsbyImage
                  image={data.social.asset.gatsbyImageData}
                  alt={data.social.alt || data.social.title}
                  className="tatto9"
                  loading="eager"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="gift_shop home_animation8">
              <a href={data.gift.slug.current} target="_blank">
                <GatsbyImage
                  image={data.gift.asset.gatsbyImageData}
                  alt={data.gift.alt || data.gift.title}
                  className="tatto10"
                  loading="eager"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
