import React, { useEffect, useState } from "react";
import Desktop from "../components/home/Desktop";
import Mobile from "../components/home/Mobile";
import useDimensions from "../hooks/useDimensions";
import "../css/home.css";
import { graphql } from "gatsby";
import SEO from "../components/common/SEO";
import Modal from "../components/common/modal";

const Home = ({ data }) => {
  const [width] = useDimensions();
  const landingPage = data.sanityHomePage;
  const [modal, setModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, setImage] = useState(landingPage.homeTemp.popup.images[0]);

  function handleNext() {
    if (currentIndex === landingPage.homeTemp.popup.images.length - 1) {
      setCurrentIndex(0);
      setImage(landingPage.homeTemp.popup.images[0]);
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setImage(landingPage.homeTemp.popup.images[currentIndex + 1]);
    }
  }

  function handlePrev() {
    if (currentIndex === 0) {
      setCurrentIndex(landingPage.homeTemp.popup.images.length - 1);
      setImage(
        landingPage.homeTemp.popup.images[
          landingPage.homeTemp.popup.images.length - 1
        ]
      );
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setImage(landingPage.homeTemp.popup.images[currentIndex - 1]);
    }
  }

  function onClose() {
    setModal(false);
  }

  useEffect(() => {
    if (landingPage.homeTemp.showPopup) {
      const timer = setTimeout(() => {
        setModal(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className="home_page_bg">
      <SEO
        title={landingPage.seo.title}
        description={landingPage.seo.description}
      />
      {!!modal && (
        <Modal
          current={image}
          handleNext={handleNext}
          handlePrev={handlePrev}
          onClose={onClose}
        />
      )}
      {width &&
        (width > 768 ? (
          <Desktop data={landingPage.homeTemp} />
        ) : (
          <Mobile data={landingPage.homeTemp} />
        ))}
    </div>
  );
};

export const query = graphql`
  {
    sanityHomePage(seo: { pagehandle: { eq: "/" } }) {
      id
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      homeTemp {
        logo {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilelogo1 {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilelogo2 {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        showPopup
        popup {
          title
          images {
            image {
              asset {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
            alt
          }
        }
        meseum {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilemeseum {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        theater {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobiletheater {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        library {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilelibrary {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        tatoo {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobiletatoo {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        gift {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilegift {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        social {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilesocial {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        cemetery {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobilecemetery {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        radio {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        mobileradio {
          alt
          title
          slug {
            current
          }
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
      }
    }
  }
`;

export default Home;
