import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Mobile = ({ data }) => {
  return (
    <div className="home_landing_mob">
      <div className="container animate_home_mobile">
        <div className="row">
          <div className="col-12 col-sm-12">
            <Link to={data.mobiletatoo.slug.current}>
              <GatsbyImage
                image={data.mobiletatoo.asset.gatsbyImageData}
                alt={data.mobiletatoo.alt || data.mobiletatoo.title}
                loading="eager"
                objectFit="contain"
                className="tatto6_m"
              />
            </Link>
            <Link to={data.mobilelogo1.slug.current}>
              <GatsbyImage
                image={data.mobilelogo1.asset.gatsbyImageData}
                alt={data.mobilelogo1.alt || data.mobilelogo1.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto2_m"
              />
            </Link>
            <center>
              <Link to={data.mobilelogo1.slug.current}>
                <GatsbyImage
                  image={data.mobilelogo2.asset.gatsbyImageData}
                  alt={data.mobilelogo2.alt || data.mobilelogo2.title}
                  loading="eager"
                  objectFit="contain"
                  className="img-fluid tatto5_m"
                />
              </Link>
            </center>
          </div>
          <div className="clearfix" />
          <div className="col-4 col-sm-4">
            <Link to={data.mobilelibrary.slug.current}>
              <GatsbyImage
                image={data.mobilelibrary.asset.gatsbyImageData}
                alt={data.mobilelibrary.alt || data.mobilelibrary.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto4_m"
              />{" "}
            </Link>
            <br />
            <Link to={data.mobilemeseum.slug.current}>
              <GatsbyImage
                image={data.mobilemeseum.asset.gatsbyImageData}
                alt={data.mobilemeseum.alt || data.mobilemeseum.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto1_m"
              />
            </Link>
          </div>
          <div className="col-4 col-sm-4">
            <Link to={data.mobiletheater.slug.current}>
              <GatsbyImage
                image={data.mobiletheater.asset.gatsbyImageData}
                alt={data.mobiletheater.alt || data.mobiletheater.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto3_m"
              />
            </Link>
            <br />
            <Link to={data.mobilecemetery.slug.current}>
              <GatsbyImage
                image={data.mobilecemetery.asset.gatsbyImageData}
                alt={data.mobilecemetery.alt || data.mobilecemetery.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto8"
              />
            </Link>
          </div>
          <div className="col-4 col-sm-4">
            <Link to={data.mobilesocial.slug.current}>
              <GatsbyImage
                image={data.mobilesocial.asset.gatsbyImageData}
                alt={data.mobilesocial.alt || data.mobilesocial.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto9_m"
              />
            </Link>
            <br />
            <Link to={data.mobileradio.slug.current}>
              <GatsbyImage
                image={data.mobileradio.asset.gatsbyImageData}
                alt={data.mobileradio.alt || data.mobileradio.title}
                loading="eager"
                objectFit="contain"
                className="img-fluid tatto7_m"
              />
            </Link>
          </div>
          <div className="clearfix" />
          <div className="col-12 col-sm-12">
            <a target="_blank" href={data.mobilegift.slug.current} />
            <center>
              <a href={data.mobilegift.slug.current} target="_blank">
                <GatsbyImage
                  image={data.mobilegift.asset.gatsbyImageData}
                  alt={data.mobilegift.alt || data.mobilegift.title}
                  loading="eager"
                  objectFit="contain"
                  className="img-fluid tatto10_m"
                />
              </a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
