import React, { Fragment, useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "../../../styles/modal.css";
import hamBurger from "../../../assets/hamburger.png";

const Modal = ({ handleNext, current, handlePrev, onClose }) => {
  const prevRef = useRef();
  const nextRef = useRef();
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleEsc = (event) => {
        if (event.keyCode === 37) {
          prevRef.current.click();
        } else if (event.keyCode === 39) {
          nextRef.current.click();
        }
      };
      window.addEventListener("keydown", handleEsc);

      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }
  }, []);
  return (
    <Fragment>
      <div
        className={`button_container_lightbox movie_tatto4 active`}
        id="toggle"
        onClick={onClose}
        style={{ zIndex: "9999999" }}
      >
        <img
          src={hamBurger}
          className="img-fluid top hamburger_menu_btn"
          alt="hamburger menu"
        />
        <br />
        <img
            src={hamBurger}
          className="img-fluid middle hamburger_menu_btn"
          alt="hamburger menu"
        />
        <br />
        <img
            src={hamBurger}
          className="img-fluid bottom hamburger_menu_btn"
          alt="hamburger menu"
        />
      </div>
      <div id="lightbox" className="popup_modal" onClick={onClose}>
        <div
          className="lb-outerContainer"
          style={{ maxWidth: 643, width: "100%", height: "100%" }}
          onClick={onClose}
        >
          <div className="lb-container" onClick={(e) => e.stopPropagation()}>
            <GatsbyImage
              image={current.image.asset.gatsbyImageData}
              alt={current.image.alt || `Gallery Image`}
              loading="lazy"
              className="lb-image"
              style={{
                display: "block",
              }}
            />
            <div className="lb-nav" style={{ display: "block" }}>
              <a
                onClick={handlePrev}
                className="lb-prev"
                style={{ display: "block" }}
                ref={prevRef}
              />
              <a
                onClick={handleNext}
                className="lb-next"
                style={{ display: "block" }}
                ref={nextRef}
              />
            </div>
            <div className="lb-loader" style={{ display: "block" }}>
              <a className="lb-cancel" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Modal;
